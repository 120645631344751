<template>
	<ObiText
		class="obi-title"
		:class="[icon && '--with-icon']"
		v-bind="attrs"
		v-on="$listeners"
	>
		<ObiText class="obi-title-icon" v-if="icon || $slots.icon">
			<slot name="icon">
				<ObiIcon
					:icon="icon"
					:icon-stacked="iconStacked"
					width="20"
					height="25"
					color="primary"
				/>
			</slot>
		</ObiText>

		<ObiText
			class="obi-title-content"
			v-if="title || subtitle || $slots.default"
		>
			<slot>
				<ObiText
					class="obi-title--title"
					:class="[color && `text-${color}`, titleClass]"
					v-if="title"
					v-html="title"
				/>
				<ObiText
					class="obi-title--subtitle"
					:class="[subtitleClass]"
					v-if="subtitle"
					v-html="subtitle"
				/>
			</slot>
		</ObiText>

		<ObiText class="obi-title-actions" v-if="$slots.actions">
			<slot name="actions" />
		</ObiText>
	</ObiText>
</template>

<script>
import { keys, omit } from "lodash";

export default {
	name: "ObiTitle",
	props: {
		title: {
			type: String,
			default: null,
		},
		titleClass: {
			type: String,
			default: null,
		},
		subtitle: {
			type: String,
			default: null,
		},
		subtitleClass: {
			type: String,
			default: null,
		},
		icon: {
			type: [String, Object],
			default: null,
		},
		iconStacked: {
			type: Boolean,
			default: null,
		},
		color: {
			type: String,
			default: null,
		},
	},
	computed: {
		attrs() {
			return omit(this.$attrs, keys(this.$props));
		},
	},
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/variables";

.obi-title {
	display: grid;
	grid-gap: 10px;
	align-items: center;
	justify-content: start;
	grid-template-columns: 1fr auto;

	&.--with-icon {
		grid-template-columns: auto 1fr auto;
	}

	.obi-title-icon {
		color: $primary;
	}

	.obi-title--title {
		color: $secondary;
		font-size: 18px;
	}
}
</style>
